import React, { useState } from 'react';
import { Header } from './components/layout/Header';
import { Map } from './components/map/Map';
import { SearchContext } from './components/context/SearchContext';
import { AddForm } from './components/addform/AddForm';
import {Routes, Route} from 'react-router-dom'

export function App() {

  const [search, setSearch] = useState('');

  return (
    <>

      <SearchContext.Provider value={{search, setSearch}}>
        <Header></Header>
        <Routes>
          <Route path='/' element = {<Map/>}/>
          <Route path='/add' element = {<AddForm/>}/>
        </Routes>
      </SearchContext.Provider>
      
    </>
  );
};