import { useContext, useEffect, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

import 'leaflet/dist/leaflet.css';
import { apiUrl } from "../../config/apiUrl";
import { SimpleAdEntity } from 'types';
import '../../utils/fix-map-icon';
import { SearchContext } from "../context/SearchContext";
import { SingleAd } from "./SingleAd";
import './map.css';

export const Map = () => {
    const {search} = useContext(SearchContext);
    const [ads, setAds] = useState<SimpleAdEntity[]>([])

    useEffect(() => {
        (async () => {

            const res = await fetch(`${apiUrl}/search/${search}`)
            const data = await res.json();
            
            setAds(data);

        })();
    }, [search]);

    return (
        
        <div className="map">
            <h1>Search for {search}</h1>
            <MapContainer center={[52.21866262354571, 21.017256638685037]} zoom={5}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution="&copy; <a href = `https://www.openstreetmap.org/copyright`> OpenStreetMap</a> &contributors"
            />
            {
                ads.map(ad => (
                    <Marker key={ad.id} position= {[ad.lat, ad.lon]}>
                        <Popup>
                            <SingleAd id={ad.id}/>
                        </Popup>
                    </Marker>
                ))
            }
            </MapContainer>
        </div>
    );
};