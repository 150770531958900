import React, { FormEvent, useContext, useState } from "react";
import { Btn } from "../common/Btn";

import './Header.css'
import { SearchContext } from "../context/SearchContext";

export const Header = () => {
   
  const {search, setSearch} = useContext(SearchContext);
  const[inputVal, setInputVal] = useState(search);
   
  const setSearchFromLocalState = (e: FormEvent) => {
    e.preventDefault();
    setSearch(inputVal);
  }
   
   
   return (<><header className='header'>
        
        
        <h1 className='title' id = 'title1'>Mega&nbsp;</h1> 

        <h1 className='title' id = 'title2'>Ogłoszenia</h1>

        <div className ="adding-ads"><Btn to="/add"  text="Dodaj ogłoszenie"/></div>
            
      
        
        <form className="search" onSubmit={setSearchFromLocalState}>
          <input type="text" value={inputVal} onChange={e => setInputVal(e.target.value)}></input><Btn text="Szukaj"/>
        </form>
        
      
      </header>
      </>)
};