import React, { useEffect, useState } from "react";
import { apiUrl } from "../../config/apiUrl";
import { AdEntity } from "types";

interface Props {
    id: string;
}

export const SingleAd = (props: Props) => {

    const [ad, setAd] = useState<AdEntity | null>(null);
    
    useEffect(() => {
        (async () => {

            const res = await fetch(`${apiUrl}/${props.id}`)
            const data = await res.json();

            setAd(data);

        })();
    }, []);

    if (ad === null) {
        return <p>Wczytywanie...</p>
    }

    return <>
        <h1>{ad.name}</h1>
        <p>{ad.description}</p>
        {!!ad.price && <p>{ad.price}</p>}
        <hr />
        <a href={ad.url} target="_blank">Otwórz</a>
        </>

} 